import React, { useState, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import debounce from 'lodash.debounce';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import 'react-toastify/dist/ReactToastify.css';
import { useSnackbar } from 'notistack';
import api from '../api';

const CompleteProfile = ({ onProfileUpdate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState('');
  const [location, setLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);

  const checkUsernameAvailability = useCallback(
    debounce(async (username) => {
      if (!username.trim()) {
        setIsUsernameAvailable(false);
        return;
      }
      try {
        const response = await api.get('/auth/check-username', {
          params: { value: username },
        });
        setIsUsernameAvailable(response.data.available);
      } catch (error) {
        console.error('Error checking username availability:', error);
      }
    }, 500),
    []
  );

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    checkUsernameAvailability(value);
  };

  const handleUpdateProfile = async () => {
    if (!username.trim()) {
      enqueueSnackbar('Username cannot be empty.', { variant: 'error' });
      return;
    }

    if (!location.trim()) {
      enqueueSnackbar('Location cannot be empty.', { variant: 'error' });
      return;
    }

    if (!selectedSuggestion) {
      enqueueSnackbar('Please select a location from the suggestions!', { variant: 'error' });
      return;
    }

    if (!isUsernameAvailable) {
      enqueueSnackbar('Username is already taken. Please choose another one.', { variant: 'error' });
      return;
    }

    setLoading(true);
    try {
      const response = await api.post('/auth/update-profile', {
        username: username.trim(),
        location: location.trim()
      });

      if (response.status === 200) {
        enqueueSnackbar('Profile updated successfully!', { variant: 'success' });
        setTimeout(() => {
          onProfileUpdate();
        }, 2000);
      } else {
        console.error('Failed to update profile');
        enqueueSnackbar('Failed to update profile!', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      enqueueSnackbar('Error updating profile!', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchSuggestions = async (query) => {
    if (!query.trim()) return;
    try {
      const response = await api.get('/auth/location-suggestions', {
        params: { query }
      });
      if (response.status === 200) {
        setSuggestions(response.data.features.map(feature => ({
          location: `${feature.properties.city}, ${feature.properties.country}`
        })));
        setSelectedSuggestion(false);
      } else {
        enqueueSnackbar('Failed to fetch suggestions!', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch suggestions!', { variant: 'error' });
    }
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    fetchSuggestions(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setLocation(suggestion.location);
    setSuggestions([]);
    setSelectedSuggestion(true);
  };

  const handleLocationBlur = () => {
    if (!selectedSuggestion) {
      setLocation('');
      setSuggestions([]);
    }
  };

  const handleLocationFocus = () => {
    if (location) {
      fetchSuggestions(location);
    }
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg relative">
      <ToastContainer />
      <h2 className="text-3xl font-bold mb-6 text-center">Complete Your Profile</h2>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">Username</label>
        <div className="flex items-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            className="flex-1 bg-transparent focus:outline-none"
          />
          {username && (
            isUsernameAvailable ? (
              <CheckCircleIcon className="h-5 w-5 text-green-500 ml-2" />
            ) : (
              <XCircleIcon className="h-5 w-5 text-red-500 ml-2" />
            )
          )}
        </div>
        {!isUsernameAvailable && (
          <p className="text-red-500 text-xs mt-2">Username is taken</p>
        )}
      </div>
      <div className="mb-6 relative">
        <label className="block text-gray-700 text-sm font-bold mb-2">Location <span className="text-sm text-gray-500">(used to determine your timezone)</span></label>
        <div className="flex items-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <input
            type="text"
            value={location}
            onChange={handleLocationChange}
            onBlur={handleLocationBlur}
            onFocus={handleLocationFocus}
            className="flex-1 bg-transparent focus:outline-none"
          />
        </div>
        {suggestions.length > 0 && (
          <ul className="border border-gray-300 rounded mt-2 bg-white max-h-48 overflow-auto absolute z-20 w-full" onMouseDown={(e) => e.preventDefault()}>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.location}
              </li>
            ))}
          </ul>
        )}
      </div>
      <button
        onClick={handleUpdateProfile}
        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Update Profile'}
      </button>
    </div>
  );
};

export default CompleteProfile;
