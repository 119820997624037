// AdminDashboard.js

import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
    Grid, Paper, Typography, Box, Button,
    ImageList, ImageListItem, ImageListItemBar,
    List, ListItem, ListItemText, Container,
    Avatar, TextField
} from '@mui/material';
import { People, Assignment, Timeline, ExitToApp } from '@mui/icons-material';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid,
    Tooltip as RechartsTooltip, Legend, ResponsiveContainer
} from 'recharts';
import { useNavigate } from 'react-router-dom';
import api from '../../api'; // Adjust the import path based on your project structure
import './AdminDashboard.css'; // Import custom CSS for additional styles
import { DataGrid } from '@mui/x-data-grid';
import ReactCountryFlag from 'react-country-flag'; // Import react-country-flag

const AdminDashboard = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [stats, setStats] = useState({
        total_users: 0,
        daily_users: 0,
        total_goals: 0,
        goals_per_user: {},
        user_growth_over_time: [],
        active_users_over_time: [],
        goal_completion_rate: 0,
        popular_goal_types: [],
        average_goal_duration: 0,
        top_users_by_streaks: [],
        recent_user_activity: [],
        geographical_distribution: [],
        goals_with_pictures: [],
        users_info: [],
        long_duration_goals: []
    });
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    const fetchStats = async () => {
        try {
            const response = await api.get('admin/admin/stats');
            setStats(response.data);
            setLoading(false);
        } catch (error) {
            enqueueSnackbar('Failed to fetch statistics', { variant: 'error' });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStats();
        const interval = setInterval(fetchStats, 300000); // Refresh every 5 minutes
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
        window.location.reload(); // Force a reload of the login page
    };

    const handleToggleUserStatus = async (email) => {
        try {
            await api.put(`admin/admin/users/${email}/status`);
            enqueueSnackbar('User status updated', { variant: 'success' });
            fetchStats(); // Refresh the stats to get updated user info
        } catch (error) {
            enqueueSnackbar('Failed to update user status', { variant: 'error' });
        }
    };

    const filteredUsers = stats.users_info.filter((user) => {
        const query = searchQuery.toLowerCase();
        return (
            user.username.toLowerCase().includes(query) ||
            user.email.toLowerCase().includes(query) ||
            user.role.toLowerCase().includes(query)
        );
    });

    if (loading) {
        return (
            <Container maxWidth="lg">
                <Box sx={{ flexGrow: 1, py: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Loading Admin Dashboard...
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1, py: 4 }}>
                {/* Header */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                    <Typography variant="h4" gutterBottom>
                        Admin Dashboard
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleLogout}
                        startIcon={<ExitToApp />}
                    >
                        Logout
                    </Button>
                </Box>
                {/* Cards */}
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" alignItems="center">
                                <Avatar sx={{ bgcolor: '#4caf50' }}>
                                    <People />
                                </Avatar>
                                <Box ml={2}>
                                    <Typography variant="subtitle1">Total Users</Typography>
                                    <Typography variant="h5">{stats.total_users}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" alignItems="center">
                                <Avatar sx={{ bgcolor: '#2196f3' }}>
                                    <Assignment />
                                </Avatar>
                                <Box ml={2}>
                                    <Typography variant="subtitle1">Daily Active Users</Typography>
                                    <Typography variant="h5">{stats.daily_users}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" alignItems="center">
                                <Avatar sx={{ bgcolor: '#ff9800' }}>
                                    <Assignment />
                                </Avatar>
                                <Box ml={2}>
                                    <Typography variant="subtitle1">Total Goals</Typography>
                                    <Typography variant="h5">{stats.total_goals}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" alignItems="center">
                                <Avatar sx={{ bgcolor: '#f44336' }}>
                                    <Timeline />
                                </Avatar>
                                <Box ml={2}>
                                    <Typography variant="subtitle1">Avg. Goal Duration</Typography>
                                    <Typography variant="h5">
                                        {stats.average_goal_duration ? stats.average_goal_duration.toFixed(2) : 0} days
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                {/* Active Users Over Time */}
                <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Active Users Over Time
                    </Typography>
                    {stats.active_users_over_time && stats.active_users_over_time.length > 0 ? (
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                                data={stats.active_users_over_time}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <RechartsTooltip />
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    ) : (
                        <Typography variant="body1">No data available.</Typography>
                    )}
                </Paper>
                {/* Geographical Distribution */}
                <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Geographical Distribution of Users
                    </Typography>
                    {stats.geographical_distribution && stats.geographical_distribution.length > 0 ? (
                        <List>
                            {stats.geographical_distribution.map((location, index) => (
                                <ListItem key={index}>
                                    <Box display="flex" alignItems="center">
                                        {location.country_code ? (
                                            <ReactCountryFlag
                                                countryCode={location.country_code}
                                                svg
                                                style={{
                                                    width: '2em',
                                                    height: '2em',
                                                    marginRight: '10px',
                                                    borderRadius: '50%',
                                                    boxShadow: '0 0 5px rgba(0,0,0,0.3)'
                                                }}
                                                title={location.country}
                                            />
                                        ) : null}
                                        <ListItemText
                                            primary={location.country}
                                            secondary={`Users: ${location.count}`}
                                        />
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body1">No geographical data available.</Typography>
                    )}
                </Paper>
                {/* Users with Unrealistically Long Goals */}
                <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Users with Unrealistically Long Goals
                    </Typography>
                    {stats.long_duration_goals && stats.long_duration_goals.length > 0 ? (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={stats.long_duration_goals}
                                columns={[
                                    { field: 'username', headerName: 'Username', width: 150 },
                                    { field: 'email', headerName: 'Email', width: 250 },
                                    { field: 'goal_title', headerName: 'Goal Title', width: 250 },
                                    { field: 'duration', headerName: 'Duration', width: 150 },
                                ]}
                                getRowId={(row) => `${row.email}-${row.goal_title}`}
                            />
                        </div>
                    ) : (
                        <Typography variant="body1">No unrealistic goals found.</Typography>
                    )}
                </Paper>
                {/* Users and Partners */}
                <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Users and Partners
                    </Typography>
                    <TextField
                        label="Search Users"
                        variant="outlined"
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    {stats.users_info && stats.users_info.length > 0 ? (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={filteredUsers}
                                columns={[
                                    { field: 'username', headerName: 'Username', width: 150 },
                                    { field: 'email', headerName: 'Email', width: 250 },
                                    { field: 'status', headerName: 'Status', width: 120 },
                                    { field: 'role', headerName: 'Role', width: 120 },
                                    {
                                        field: 'partners',
                                        headerName: 'Partners',
                                        width: 300,
                                        renderCell: (params) => params.value.join(', ')
                                    },
                                    {
                                        field: 'actions',
                                        headerName: 'Actions',
                                        width: 150,
                                        renderCell: (params) => (
                                            <Button
                                                variant="contained"
                                                color={params.row.status === 'active' ? 'secondary' : 'primary'}
                                                onClick={() => handleToggleUserStatus(params.row.email)}
                                            >
                                                {params.row.status === 'active' ? 'Block' : 'Unblock'}
                                            </Button>
                                        )
                                    }
                                ]}
                                getRowId={(row) => row.email}
                            />
                        </div>
                    ) : (
                        <Typography variant="body1">No user data available.</Typography>
                    )}
                </Paper>
                {/* Goals with Pictures */}
                <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Goals with Pictures
                    </Typography>
                    {stats.goals_with_pictures && stats.goals_with_pictures.length > 0 ? (
                        <ImageList cols={3} gap={8}>
                            {stats.goals_with_pictures.map((goal) =>
                                goal.pictures.map((pictureData, index) => (
                                    <ImageListItem key={`${goal.user_id}-${goal.title}-${index}`}>
                                        <img
                                            src={pictureData.picture}
                                            alt={`Goal: ${goal.title} - Day ${pictureData.day}`}
                                            loading="lazy"
                                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                        />
                                        <ImageListItemBar
                                            title={`Goal: ${goal.title}`}
                                            subtitle={`Day ${pictureData.day}`}
                                        />
                                    </ImageListItem>
                                ))
                            )}
                        </ImageList>
                    ) : (
                        <Typography variant="body1">No goals with pictures available.</Typography>
                    )}
                </Paper>
                {/* Recent User Activity */}
                <Paper elevation={3} sx={{ p: 3, mt: 5, mb: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Recent User Activity
                    </Typography>
                    {stats.recent_user_activity && stats.recent_user_activity.length > 0 ? (
                        <List>
                            {stats.recent_user_activity.map((activity, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={activity.username}
                                        secondary={`Last login: ${
                                            activity.last_login
                                                ? new Date(activity.last_login).toLocaleString()
                                                : 'Never'
                                        }`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body1">No recent user activity.</Typography>
                    )}
                </Paper>
            </Box>
        </Container>
    );
};

export default AdminDashboard;
